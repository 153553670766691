<template>
  
  <b-modal v-model="modalShow" size="lg" :title="$t('staff.planner_settings.title')" footer-class="footerClass"
    @hidden="$emit('update:show', false)"
    content-class="shadow"
    no-close-on-backdrop
    scrollable
  >
    <b-form-group :label="$t('staff.timescale')">
      <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.weekNumbers">
        {{ $t('staff.week_numbers') }}
      </b-form-checkbox>
      <b-form-radio-group v-model="settingsData.weekBase">
        <b-form-radio class="setting-checkbox" size="lg" name="weekBase" value="calendar_year">{{ $t('task.calendar_year') }}</b-form-radio>
        <b-form-radio class="setting-checkbox" size="lg" name="weekBase" value="project_start">{{ $t('task.project_start') }}</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  
    <b-form-checkbox class="setting-checkbox" size="lg" v-model="settingsData.hideWeekends">
      {{ $t('staff.hide_weekends') }}
    </b-form-checkbox>
    
    <template v-slot:modal-footer="{ cancel }">
      <b-button :disabled="!hasChanged" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
      <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { cloneDeep } from 'lodash';
export default {
  name: 'TaskGanttSettingsModal',
  components: {
    
  },
  props: {
    show:         { type: Boolean, required: true },
    settings:     { type: Object, default: null }
  },
  data() {
    return {
      id: Math.random().toString(36).substring(2, 9),
      modalShow: false,
      settingsData: {
        hideWeekends: false,
        weekNumbers: false,
        weekBase: 'calendar_year'
      }
    }
  },
  created() {
    this.options = this.properties;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        if (newValue) {
          this.init();
        }
      }
    },
  },
  computed: {
    hasChanged() {
      return this.settings != null && 
      (this.settingsData.hideWeekends != this.settings.hideWeekends ||
      this.settingsData.weekNumbers != this.settings.weekNumbers ||
      this.settingsData.weekBase != this.settings.weekBase);
    }
  },
  methods: {
    init() {
      this.settingsData = cloneDeep(this.settings);
    },
    ok() {
      this.$emit('ok', this.settingsData );
      this.$emit('update:show', false);
    }
  }
}
</script>
